<template>
  <v-container fluid>
    <!-- <div class="d-flex mb-5">
      <div class="conOfDataRang mt-5">
        <label class="dataRang">Data Rang</label>
        <VueRangedatePicker
          v-model="datepicker"
          color="primary"
        ></VueRangedatePicker>
      </div>
    </div> -->
    <div>
      <v-col cols="12" md="12" class="d-flex justify-end">
        <v-btn class="btnAddPunches" @click="dialogPunch = true">
          Add Punches
        </v-btn>
      </v-col>
      <v-col
        :key="index"
        v-for="(item, index) in listData"
        cols="12"
        class="px-0"
        md="12"
      >
        <div class="conOfDataRang">
          <v-row>
            <v-col cols="12" md="3">
              <div class="dateTitle">Day</div>
              <div class="dateDetails">
                {{ item.day }}
              </div>
            </v-col>
            <v-col cols="12" md="3">
              <div class="dateTitle">From</div>
              <div class="dateDetails">
                {{ item.start }}
              </div>
            </v-col>
            <v-col cols="12" md="3">
              <div class="dateTitle">To</div>
              <div class="dateDetails">
                {{ item.end }}
              </div>
            </v-col>
            <v-col>
              <v-btn
                cols="12"
                md="3"
                class="btnAddPunches"
                @click="updatePunch(item)"
              >
                Edit
              </v-btn></v-col
            >
          </v-row>
        </div>
      </v-col>
    </div>
    <v-dialog v-model="dialogPunch" max-width="500" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">ADD Punch</span>
        </v-card-title>

        <v-card-text class="conFormSubmit">
          <v-container>
            <v-row>
              <v-col cols="12">
                <label class="eachLabel">Day</label>
                <v-text-field
                  v-model="formItem.day"
                  class="eachInput"
                  placeholder="Day"
                  outlined
                  solo
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label class="eachLabel">Start</label>
                <v-text-field
                  v-model="formItem.start"
                  class="eachInput"
                  type="time"
                  value="00:00:00"
                  placeholder="Start"
                  step="1"
                  outlined
                  solo
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label class="eachLabel">End</label>
                <v-text-field
                  v-model="formItem.end"
                  class="eachInput"
                  placeholder="End"
                  type="time"
                  value="00:00:00"
                  step="1"
                  outlined
                  solo
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="dialogPunch = false">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="addPunch()"
            :disabled="isLoadingSavePO"
            :loading="isLoadingSavePO"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditPunch" max-width="500" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">Edit Punch</span>
        </v-card-title>

        <v-card-text class="conFormSubmit">
          <v-container>
            <v-row>
              <v-col cols="12">
                <label class="eachLabel">Day</label>
                <v-text-field
                  v-model="formItem.day"
                  class="eachInput"
                  placeholder="Day"
                  outlined
                  solo
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label class="eachLabel">Start</label>
                <v-text-field
                  v-model="formItem.start"
                  class="eachInput"
                  type="time"
                  value="00:00:00"
                  placeholder="Start"
                  step="1"
                  outlined
                  solo
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label class="eachLabel">End</label>
                <v-text-field
                  v-model="formItem.end"
                  class="eachInput"
                  placeholder="End"
                  type="time"
                  value="00:00:00"
                  step="1"
                  outlined
                  solo
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="dialogEditPunch = false">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="confirmEditTask()"
            :disabled="isLoadingSavePO"
            :loading="isLoadingSavePO"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import VueRangedatePicker from "vue-rangedate-picker";
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  components: {
    // VueRangedatePicker,
  },
  data() {
    return {
      dialogPunch: false,
      dialogEditPunch: false,
      formItem: {
        id: "",
        day: "",
        start: "",
        end: "",
      },
      isLoadingSave: false,
      listData: {},
      datepicker: null,
    };
  },
  methods: {
    getListData() {
      this.listData = {};

      this.isLoading = true;
      apiServices.get(`punches`).then((res) => {
        if (res) {
          this.listData = res.data;
          console.log("punches", this.listData);
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
    addPunch() {
      const errors = [];
      this.isLoadingSave = true;
      apiServices.post("punches", this.formItem).then((res) => {
        if (res) {
          this.isLoadingSave = false;
          this.getListData();
          this.dialogPunch = false;
        } else {
          this.isLoadingSave = false;
          return errors;
        }
      });
    },
    updatePunch(item) {
      let editFormItem = {
        punch_id: item.id,
        day: item.day,
        start: item.start,
        end: item.end,
      };
      this.formItem = editFormItem;
      this.dialogEditPunch = true;
    },
    confirmEditTask() {
      const errors = [];
      this.isLoadingSave = true;
      apiServices.post("update/punch", this.formItem).then((res) => {
        if (res) {
          this.isLoadingSave = false;
          this.getListData();
          this.dialogEditPunch = false;
          this.formItem = "";
        } else {
          this.isLoadingSave = false;
          return errors;
        }
      });
    },
  },
  created() {
    this.getListData();
  },
};
</script>

<style lang="scss" scoped>
@import "./_timesheets.scss";
</style>
